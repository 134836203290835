<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        :lazy-validation="lazy"
                        class="w-100 pa-8">
                    <v-container fluid>
                        <dynoform @change="UpdateFormField($event, 'formdata')"
                                  @changeGrid="UpdateExtData($event, $event.tableName)"
                                  @click="FormButtonClicked($event)"
                                  :attributes="GetFormAttributes"
                                  @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                  @HeaderButtonClicked="HeaderButtonClickedDyno($event)"
                                  @changeAttribute="ChangeAttribute($event,'formdata')"
                                  :tabname="tab"
                                  :durum="durum"></dynoform>
                    </v-container>

                    <v-container fluid>
                        <grid :columns="GetExdataColumns('siparisler')"
                              :data="GetExdataItems('siparisler')"
                              :header="$t('FL_OrderItems')"
                              @change="UpdateExtData($event, 'siparisler')"
                              @search="Search($event)"
                              @selected="Selected($event)"
                              @rowclicked="RowClicked($event, 'siparisler')"
                              :class="{ gridError: Error }"
                              :error="Error"
                              @SearchInputChanged="SearchInputChanged($event, 'siparisler')"
                              @RowButtonClicked="RowButtonClicked($event, 'siparisler')"
                              @AddRow="AddRow2ExtData($event, 'siparisler')"
                              :attributes="GetExtdataAttributes('siparisler')"
                              addrow=""
                              :clickrow="1 == 1" />
                    </v-container>
                </v-form>
            </div>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="!isgformsdataloaded" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="!isgformsdataloaded" class="primary--text overline">{{ $t("IM_RetrievingData") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-for="(action, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="PageAction(action)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="action.action">
                    {{ $t(action.label) }}
                </v-btn>
            </v-footer>
        </v-main>

    </div>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';

    Vue.use(Vuex);

    var siparisler = {
        tableName: 'siparisler',
        label: 'TH_OrderItems',
        columns: {
            select:
            {
                type: 'checkbox',
                name: 'select',
                label: 'AL_Select',
                edit: '01',
                width: 10,
                truevalue: 1,
            },
            formid: {
                type: 'int',
                name: 'formid',
                label: 'FL_OrderNo',
                edit: '',
                width: 10,
            },
            tarih: {
                type: 'date',
                name: 'tarih',
                label: 'FL_Date',
                edit: '',
                width: 10,
            },
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_OrderItems',
                edit: '',
                width: 20,
            },
            markamodel: {
                type: 'text',
                name: 'markamodel',
                label: 'FL_Brand',
                edit: '',
                width: 20,
            },
            alisfiyat: {
                type: 'number',
                name: 'alisfiyat',
                label: 'FL_PurchasePrice',
                edit: '',
                width: 10,
            },
            doviz: {
                type: 'text',
                name: 'doviz',
                label: 'FL_Currency',
                edit: '',
                width: 10,
            },
            talepmiktar: {
                type: 'number',
                name: 'talepmiktar',
                label: 'FL_OrderedAmount',
                edit: '',
                width: 10,
            },
            gelenmiktar: {
                type: 'number',
                name: 'gelenmiktar',
                label: 'FL_Received',
                edit: '',
                width: 10,
            },
            onaybekleyenmiktar: {
                type: 'number',
                name: 'onaybekleyenmiktar',
                label: 'FL_WaitingApproval',
                edit: '',
                width: 10,
            },
            miktar: {
                type: 'number',
                name: 'miktar',
                label: 'FL_ItemstoSend',
                edit: '01',
                width: 10,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Siparis_Kalemler'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var dynoFormInfo = {
        tabs: [
            { order: 1, header: 'TH_OrderSearchCriteria' },

        ],
        TabAttributes: {
            "1": { show: true },
        },
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_Criteria'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_WaybillInformation'
            },
        ],

        Columns: {
            id: {
                type: 'text',
                group: 1,
                sira: 10,
                name: 'formid',
                label: 'FL_OrderNo',
                edit: '01',
                col: '6',

            },
            ad:
            {
                type: 'text',
                name: 'ad',
                label: 'OrderItem',
                group: 1,
                sira: 20,
                col: '6',
                edit: '01',
            },
            tarihbas: {
                type: 'date',
                group: 1,
                sira: 30,
                name: 'tarihbas',
                label: 'FL_StartDate',
                edit: '01',
                col: '6',
            },
            tarihbit: {
                type: 'date',
                group: 1,
                sira: 35,
                name: 'tarihbit',
                label: 'FL_EndDate',
                edit: '01',
                col: '6',
            },
            status:
            {
                type: 'select',
                name: 'status',
                label: 'FL_Status',
                group: 1,
                sira: 40,
                col: '6',
                edit: '01',
                options: [
                    //Filled At Created Method
                ],
            },
            ara:
            {
                type: 'button',
                group: 1,
                sira: 90,
                name: 'ara',
                label: 'AL_SearchOrder',
                buttontext: 'SearchOrder',
                buttoncolor: 'primary',
                edit: '01',
                col: 6,
            },
            duzenlemetarihi:
            {
                type: 'date',
                name: 'duzenlemetarihi',
                label: 'FL_ArrangementDate',
                group: 2,
                sira: 10,
                col: 6,
                edit: '01',
            },
            sevktarihi:
            {
                type: 'date',
                name: 'sevktarihi',
                label: 'FL_ShipmentDate',
                group: 2,
                sira: 10,
                col: 6,
                edit: '01',
            },
            not:
            {
                type: 'text',
                name: 'not',
                label: 'FL_Note',
                group: 2,
                sira: 20,
                col: 12,
                edit: '01',
            },
            tedarikciek1file:
            {
                type: 'file',
                name: 'tedarikciek1file',
                label: 'FL_AdditionalFiles',
                group: 2,
                sira: 25,
                col: 6,
                edit: '01',
            },
            irsaliyelisevk:
            {
                type: 'select',
                name: 'irsaliyelisevk',
                label: 'FL_ShipmentWithWaybill',
                group: 2,
                sira: 30,
                col: '6',
                edit: '01',
                options: [
                    //Filled At Created Method
                ],
            },
            irsaliyeno:
            {
                type: 'text',
                name: 'irsaliyeno',
                label: 'FL_WaybillNo',
                group: 2,
                sira: 30,
                col: 6,
                edit: '01',
            },
            irsaliyenushasi:
            {
                type: 'file',
                name: 'irsaliyenushasi',
                label: 'FL_WaybillCopy',
                group: 2,
                sira: 40,
                col: 6,
                edit: '01',
            },
            tasimatalepformid:
            {
                type: 'select',
                name: 'tasimatalepformid',
                label: 'FL_TeslimAlacak',
                group: 2,
                sira: 45,
                col: '6',
                edit: '01',
                options: [
                    //Filled At Created Method
                ],
            },

        },
        form: 'siparis',
        formid: 0,
        formdata: {
            id: 0,
            tarihbas: null,
            tarihbit: null,
            state: '0',
            status: '',
            irsaliyelisevk: '',
            formid: '',
            ad: '',
        },
        viewName: 'tumu',
        viewparameter: '',

        files: [],
        extdata: [siparisler],
        actions: ['Gönder'],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import { all } from 'core-js/fn/promise';
    import Utilities from '@/Utilities.js'

    function ad_AE(payload) {
        /*alert('AE')*/
    }

    export default {
        name: 'siparisler',
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: { type: String, default: 'tumu' },
            drawer: { type: Boolean, default: true },
            single: { type: Boolean, default: false },
            Error: { type: Boolean, default: false },
        },
        data: function () {
            return {
                valid: false,
                lazy: true,
                viewname: 'tumu',
                form: 'tedarik',
                validateError: '',
                username: '',
                formname: 'siparisler',
                tab: 'Tab-1',
                durum: null,
                isgformsdataloaded: false,
                transferAccountingCodes: '',
                transferControl: '0'
            };
        },
        computed: {
            GetState() {
                return this.$store.getters.GetFormState;
            },
        },
        methods: {
            SetGFormsData: function () {
                var formdata = this.GetFormData;
                Utilities.log("\\\\", formdata["kformsid"].toString().trim());
                var payload = {};
                payload.action = "GetTedarikData"
                payload.data = [{ id: formdata["kformsid"].toString().trim() }];
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        Utilities.log("Runtime  success result:", response);
                        this.gformsdata = response.data.RunTimeResult[0];
                        this.durum = this.gformsdata["durum"];
                        this.isgformsdataloaded = true;
                    })
                    .catch(err => {
                        Utilities.log("Runtime erroro result:", err);
                    })

            },
            FormLoaded: function () {
                Utilities.log('FORM LOADED');
                var formdata = this.GetFormData;
                var groupName = formdata.GroupName;
                if (formdata.state != "0" || formdata.state != "1") {

                }
                if (formdata.state != "0" && formdata.state != "" && formdata.state != "1") {
                    var self = this;
                    var payload = {};
                    payload.form = 'irsaliye';
                    payload.formid = formdata.id;
                    payload.parameter = formdata.Barcode;
                    this.$store.dispatch('GetFormRoles', payload)
                        .then((response) =>
                        {
                            return;

                        })
                }
                else {
                    this.propertyeditstates = '012'
                }
            },

            Selected: function ()
            {
                return;
            },

            PageAction: function (action) {
                var messages = {};
                var validateResult = this.ValidateForm();
                if (validateResult && validateResult.length > 0) {
                    messages = {
                        errors: validateResult,
                        infoBody: '',
                        messageType: 'E',
                        viewType: 'notification',
                    };
                    this.$root.$emit('setUpDialog', messages);
                    return;
                }

                var payload = {
                    form: 'siparislerkalemler',
                    formid: 0,
                    action: action,
                    viewName: ''
                };

                this.actionBarWait = true;
                var self = this;
                this.$store
                    .dispatch('SendFormAction', payload)
                    .then(response => {
                        self.actionBarWait = false;

                        if (response.data.errorCode == 0) {
                            messages = {
                                errors: [this.$root.$i18n.t("DM_AskRedirectToForm", { form: this.$root.$i18n.t("FN_Waybill") })],
                                infoBody: '',
                                messageType: 'CB',
                                viewType: 'dialog',
                                OnApprove: function (message) {
                                    self.$router.push({
                                        name: 'irsaliyeform',
                                        params: { id: response.data.infoMessage }
                                    });
                                }
                            };

                            // Grid'i update et
                            let self = this;
                            this.$store.dispatch("GetExtData", 'siparisler')
                                .then(response => {
                                    Utilities.log("Got Extdata", response);
                                    var axiosCount = self.GetAxiosCount;
                                    if (axiosCount == 0 && typeof self.FormLoaded == "function") self.FormLoaded();
                                })
                                .catch(err => {
                                    Utilities.log("Hata:", err);
                                    messages = {
                                        errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                                        infoBody: '',
                                        messageType: 'E',
                                        viewType: 'notification',
                                    };
                                });

                            this.$store.commit("ClearForm");
                        }
                        else {
                            messages = {
                                errors: [this.$root.$i18n.t(response.data.errorMessage)],
                                infoBody: '',
                                messageType: 'E',
                                viewType: 'notification',
                            };
                        }
                        this.$root.$emit('setUpDialog', messages);
                    })
                    .catch(error => {
                        Utilities.log('FormAction catch error:', error);
                        this.actionBarWait = false;
                        messages = {
                            errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                            infoBody: '',
                            messageType: 'E',
                            viewType: 'notification',
                        };
                        this.$root.$emit('setUpDialog', messages);
                    });
            },
            FormButtonClicked(col) {
                Utilities.log("Form button clicked", col)
                if (col.name == 'ara') {
                    let self = this;
                    this.$store.dispatch("GetExtData", 'siparisler')
                        .then(response => {
                            Utilities.log("Got Extdata", response);
                            var axiosCount = self.GetAxiosCount;
                            if (axiosCount == 0 && typeof self.FormLoaded == "function") self.FormLoaded();
                        })
                        .catch(err => {
                            Utilities.log("Hata:", err);
                            this.actionBarWait = false;
                            var messages = {
                                errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                                infoBody: '',
                                messageType: 'E',
                                viewType: 'notification',
                            };
                            this.$root.$emit('setUpDialog', messages);
                        });
                }
            },
            FieldButtonClicked(col)
            {
                return;
            },
            RowClicked: function (rowdata, table) {
                Utilities.log('clicked on table:', table, ' Row:', rowdata);
                if (rowdata.item.select) {
                    var kalemler = this.GetExdataItems(table);
                    for (var index in kalemler) {
                        if (kalemler[index].select == 1 && kalemler[index].formid != rowdata.item.formid) {
                            var messages = {
                                errors: [this.$root.$i18n.t('EM_MultipleOrderSelected')],
                                infoBody: '',
                                messageType: 'W',
                                viewType: 'dialog',
                            };
                            this.$root.$emit('setUpDialog', messages);
                            rowdata.item.select = 0;
                            break;
                        }
                    }
                }
                var found = false;
                var kalemler = this.GetExdataItems(table);
                for (var index in kalemler) {
                    if (kalemler[index].select == 1 && this.transferAccountingCodes.indexOf(kalemler[index].muhasebekodu) != -1) {
                        found = true;
                    }
                }
                if (found) {
                    this.ClearFieldHidden('tasimatalepformid');
                    this.transferControl = "1";
                }
                else {
                    this.SetFieldHidden('tasimatalepformid');
                    this.transferControl = "0";
                }
            },
            RowButtonClicked: function (payload, tableName)
            {
                return;
            },
            HeaderButtonClickedDyno(payload) {
                return;
            },
            HeaderButtonClickedasdasd(payload, tableName) {
                return;
            },
            irsaliyelisevk_Field_Changed: function (vals) {
                if (vals.fieldValue == "1") {
                    this.ClearFieldHidden('irsaliyeno');
                    this.ClearFieldHidden('irsaliyenushasi');
                } else {
                    this.SetFieldHidden('irsaliyeno');
                    this.SetFieldHidden('irsaliyenushasi');
                }
            },
            ChangeAttribute(e, tableName) {

                var payload = {};
                payload.attributeName = e.attributeName;
                payload.fieldName = e.column.name;
                payload.value = e.value;
                payload.tableName = tableName;
                Utilities.log("FORM Emit changeAttribute", payload)
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm: function (action) {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                var formdata = this.GetFormData;

                var err;
                if (formdata.irsaliyelisevk == "1") {
                    if (formdata.irsaliyeno == "") {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_WaybillNo') });
                        this.SetFieldError('irsaliyeno');
                        this.errors.push(err);
                    }
                    if (formdata.irsaliyenushasi == "") {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_WaybillCopy') });
                        this.errors.push(err);
                        this.SetFieldError('irsaliyenushasi');
                    }
                }
                if (formdata.duzenlemetarihi == "") {
                    err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ArrangementDate') });
                    this.errors.push(err);
                    this.SetFieldError('duzenlemetarihi');
                }
                if (formdata.not == "") {
                    err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                    this.errors.push(err);
                    this.SetFieldError('not');
                }
                if (formdata.sevktarihi == "") {
                    err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ShipmentDate') });
                    this.errors.push(err);
                    this.SetFieldError('sevktarihi');
                }
                var items = this.GetExdataItems('siparisler');
                if (items.length == 0) {
                    err = this.$root.$i18n.t('EM_NoOrderItemListed');
                    this.errors.push(err);
                    this.Error = true;
                } else {
                    var selectedItems = items.filter(kalem => kalem.select == 1);
                    if (selectedItems.length == 0) {
                        err = this.$root.$i18n.t('EM_NoOrderItemSelected');
                        this.errors.push(err);
                        this.Error = true;
                    }
                    else {
                        for (var index in selectedItems) {
                            if (selectedItems[index].miktar == 0) {
                                err = this.$root.$i18n.t('EM_OrderItemAmountDoesCantZero');
                                this.errors.push(err);
                                this.Error = true;
                            }
                            var totalMiktar = (Number(selectedItems[index].gelenmiktar) + Number(selectedItems[index].onaybekleyenmiktar) + Number(selectedItems[index].miktar));
                            if (totalMiktar > Number(selectedItems[index].talepmiktar)) {
                                err = this.$root.$i18n.t('EM_SentItemCountError') + '</br>' + this.$root.$i18n.t('FL_OrderedAmount') + ': ' + selectedItems[index].talepmiktar + '</br>' + this.$root.$i18n.t('FL_SentQuantity') + ': ' + totalMiktar;
                                this.errors.push(err);
                                this.Error = true;
                            }
                        }
                    }
                }

                if (this.errors.length == 0 && this.transferControl == "1") {
                    if (formdata.tasimatalepformid == "") {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_TeslimAlacak') });
                        this.errors.push(err);
                        this.SetFieldError('tasimatalepformid');
                    }
                }

                if (this.errors.length > 0)
                {
                    result = this.errors;
                }
                return result;
            },
        },

        created: function () {
            var columns = dynoFormInfo.Columns;
            var attributes = {};
            for (var key in columns) {
                if (columns.hasOwnProperty(key)) {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false,
                        approved: true,
                        hidden: false
                    };
                }
            }
            dynoFormInfo.attributes = attributes;

            dynoFormInfo.Columns.status.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('OP_Active'), value: 'Active' },
                { text: this.$root.$i18n.t('OP_All'), value: 'Done' },
            ];
            dynoFormInfo.Columns.irsaliyelisevk.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('OP_Yes'), value: '1' },
                { text: this.$root.$i18n.t('OP_No'), value: '0' },
            ];


            var self = this;
            var req = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "GetTasimaTalepleri",
                notAuthorizedRequest: false
            };
            req.success = function (result) {
                if (result.data.errorCode == 0) {
                    result.data.list.forEach(function (obj) {
                        var listItem = {
                            text: obj.owner + ' - ' + moment(obj.tasinmatarihi).format('DD/MM/YYYY'),
                            value: obj.id
                        };
                        dynoFormInfo.Columns.tasimatalepformid.options.push(listItem);
                    });
                } else {
                    self.ShowDialog(self.$root.$i18n.t(result.data.errorMessage), 'E', 'dialog');
                }
            };
            req.error = function (err) {
                self.ShowDialog(self.$root.$i18n.t('EM_UnexpectedErrorTryAgain'), 'E', 'dialog');
            };

            this.$store.dispatch("GetLOV", req);

            var req = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "GetTransferAccountingCodes",
                notAuthorizedRequest: false
            };
            req.success = function (result) {
                if (result.data.errorCode == 0) {
                    if (result.data.list.length == 1) {
                        self.transferAccountingCodes = result.data.list[0]["fieldname"];
                    }
                }
            };
            req.error = function (err) {
                
            };

            this.$store.dispatch("GetLOV", req);
        },
        mounted: function () {
            this.$store.commit('updateFormInfo', dynoFormInfo);
            this.$store.commit('SetFormData', dynoFormInfo.formdata);
            this.$store.commit('SetFormActions', [{
                label: 'AL_CreateShipment',
                action: 'createwaybill'
            }]);
            this.isgformsdataloaded = true;
            this.SetFieldHidden('irsaliyeno');
            this.SetFieldHidden('irsaliyenushasi');
            this.SetFieldHidden('tasimatalepformid');
        },
    };
</script>
